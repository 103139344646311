<template>
  <SuperCombobox
    v-bind="forwarded"
    v-model="commercialConditionStatuses"
    v-model:search-term="searchTerm"
    :options="commercialConditionStatusOptionsAfterSearch"
  >
    <template #selected-option="{ item: commercialConditionStatus, unselect }">
      <!-- The `style="margin-block: 3px"` fixes a UI glitch because SuperComboboxSearch is designed to work well with elements that are 32px height by default. -->
      <CommercialConditionStatusBadge :status="commercialConditionStatus" style="margin-block: 3px">
        <StatusBadgeRemove v-if="isSelectedOptionRemovable" @click="unselect(commercialConditionStatus)" />
      </CommercialConditionStatusBadge>
    </template>

    <template #option="{ item: commercialConditionStatus, isActive, isSelected }">
      <SuperComboboxItem :is-active="isActive">
        <SuperComboboxItemIndicator v-if="isSelected" />
        <CommercialConditionStatusBadge :status="commercialConditionStatus" />
      </SuperComboboxItem>
    </template>
  </SuperCombobox>
</template>

<script setup lang="ts">
import Fuse from "fuse.js";
import type { CommercialConditionStatus } from "@asap/shared";
import type {
  Props as SuperComboboxProps,
  Emits as SuperComboboxEmits,
} from "~/components/ui/super-combobox/SuperCombobox.vue";
import { useForwardPropsEmits } from "radix-vue";
import { translateCommercialConditionStatus } from "@asap/shared";
import CommercialConditionStatusBadge from "./CommercialConditionStatusBadge.vue";
import { useManagees } from "~/composables/useManagees";
import { useUserMe } from "~/composables/useUser";

export type Props = SuperComboboxProps<CommercialConditionStatus> & {
  isAsapCommercialCondition?: boolean;
  ownerId?: string;
};
export type Emits = SuperComboboxEmits<CommercialConditionStatus>;

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const { modelValue: commercialConditionStatuses } = useVModels(props, emit);
const { isMyManagee } = useManagees();
const { isIndependentAdministrator } = useUserMe();

const forwarded = useForwardPropsEmits(props, emit);

const searchTerm = ref("");

const commercialConditionStatusOptions = computed<CommercialConditionStatus[]>(() => {
  const options = ["not_signed", "to_validate", "signed", "refused", "canceled"] as CommercialConditionStatus[];
  const hasEditableRole = isMyManagee(props.ownerId) || isIndependentAdministrator.value;

  if (props.isAsapCommercialCondition) return options.filter((s) => s !== "to_validate");

  if (
    !props.isAsapCommercialCondition &&
    (commercialConditionStatuses.value.includes("to_validate") ||
      commercialConditionStatuses.value.includes("canceled")) &&
    !hasEditableRole
  ) {
    return options.filter((s) => s === "to_validate" || s === "canceled");
  }

  return options;
});

const searchFilter = function (commercialConditionStatuses: CommercialConditionStatus[], searchTerm: string) {
  if (!searchTerm) return commercialConditionStatuses;

  // TODO: It seems like applying `getFn: (commercialConditionStatus) => translateCommercialConditionStatus.fr[commercialConditionStatus]` does not work when options is a list of string
  const stages = commercialConditionStatuses.map(
    (commercialConditionStatus) => translateCommercialConditionStatus.fr[commercialConditionStatus]
  );

  const fuse = new Fuse(stages, {
    threshold: 0.2,
  });

  const items = fuse.search(searchTerm).map((result) => commercialConditionStatuses[result.refIndex]);

  return items;
};

const commercialConditionStatusOptionsAfterSearch = computed<CommercialConditionStatus[]>(() =>
  searchFilter(commercialConditionStatusOptions.value, searchTerm.value)
);
</script>

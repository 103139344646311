<template>
  <FilterRow>
    <FilterItems>
      <!-- ---------- -->
      <!-- Search Bar -->
      <!-- ---------- -->

      <FilterSearch v-model="search" />

      <!-- ------------------ -->
      <!-- Main Filter Butt©on -->
      <!-- ------------------ -->

      <FilterMainButton :count="countFilters" :is-active="showCommandModal" @on-filter="showCommandModal = true" />

      <CommandModal
        v-if="showCommandModal"
        placeholder="Sélectionner un filtre"
        :options="commandOptions"
        @on-close="showCommandModal = false"
      />

      <!-- ---- -->
      <!-- Type -->
      <!-- ---- -->

      <FilterButton v-if="commercialConditionTypes.length" @click="showCommercialConditionTypeSelectModal = true">
        {{ mapEmoji.type }} Type
        <template v-if="commercialConditionTypes.length === 1">
          <Badge>
            {{ translateCommercialConditionType.fr[commercialConditionTypes[0]] }}
          </Badge>
        </template>
        <Badge v-else>{{ commercialConditionTypes.length }} sélectionnés</Badge>
      </FilterButton>

      <LazyCommercialConditionTypeSelectModal
        v-if="showCommercialConditionTypeSelectModal"
        v-model="commercialConditionTypes"
        @on-close="showCommercialConditionTypeSelectModal = false"
      />

      <!-- ------------- -->
      <!-- Business Unit -->
      <!-- ------------- -->

      <FilterButton v-if="businessUnits.length" @click="showBusinessUnitSelectModal = true">
        {{ mapEmoji.business_unit }}
        Business Unit
        <template v-if="businessUnits.length < 4">
          <Badge v-for="businessUnit in businessUnits" :key="businessUnit.id" size="sm">
            {{ businessUnit.name }}
          </Badge>
        </template>
        <Badge v-else>{{ businessUnits.length }} sélectionnés</Badge>
        <FilterClear @click.stop="businessUnits = []" />
      </FilterButton>

      <LazyBusinessUnitSelectModal
        v-if="showBusinessUnitSelectModal"
        v-model="businessUnits"
        @on-close="showBusinessUnitSelectModal = false"
      />

      <!-- ------ -->
      <!-- Status -->
      <!-- ------ -->

      <FilterButton v-if="commercialConditionStatuses.length" @click="showCommercialConditionStatusSelectModal = true">
        {{ mapEmoji.status }}
        Statut
        <template v-if="commercialConditionStatuses.length">
          <Badge v-for="status in commercialConditionStatuses" :key="status">
            {{ translateCommercialConditionStatus.fr[status] }}
          </Badge>
        </template>
        <FilterClear @click.stop="commercialConditionStatuses = []" />
      </FilterButton>

      <LazyCommercialConditionStatusSelectModal
        v-if="showCommercialConditionStatusSelectModal"
        v-model="commercialConditionStatuses"
        @on-close="showCommercialConditionStatusSelectModal = false"
      />
    </FilterItems>
  </FilterRow>
</template>
<script setup lang="ts">
import type { BusinessUnit } from "~/utils/supabase.types";
import type { ContextMenuOption } from "~/components/ui/command/Command.vue";
import type { CommercialConditionStatus, CommercialConditionType } from "@asap/shared";
import { translateCommercialConditionType, translateCommercialConditionStatus } from "@asap/shared";

const showCommandModal = ref(false);

const commandOptions = computed<ContextMenuOption[]>(() => [
  {
    label: "Filtrer par Statut",
    emoji: mapEmoji.mission_status,
    action: () => {
      showCommercialConditionStatusSelectModal.value = true;
      showCommandModal.value = false;
    },
  },
  {
    label: "Filtrer par Business Unit",
    emoji: mapEmoji.business_unit,
    action: () => {
      showBusinessUnitSelectModal.value = true;
      showCommandModal.value = false;
    },
  },
  {
    label: "Filtrer par Type",
    emoji: mapEmoji.type,
    action: () => {
      showCommercialConditionTypeSelectModal.value = true;
      showCommandModal.value = false;
    },
  },
]);

const search = defineModel<string>("search");

const businessUnits = defineModel<BusinessUnit[]>("businessUnits", { default: [] });
const showBusinessUnitSelectModal = ref(false);

const commercialConditionTypes = defineModel<CommercialConditionType[]>("commercialConditionTypes", { default: [] });
const showCommercialConditionTypeSelectModal = ref(false);

const commercialConditionStatuses = defineModel<CommercialConditionStatus[]>("commercialConditionStatuses", {
  default: [],
});
const showCommercialConditionStatusSelectModal = ref(false);

const { teamMember } = useUserMe();

onMounted(() => {
  if (!teamMember.value) return;

  if (teamMember.value.business_unit) {
    businessUnits.value = [teamMember.value.business_unit];
  }
});

const countFilters = computed<number>(() => {
  let count = 0;
  if (businessUnits.value.length) count++;
  if (commercialConditionStatuses.value.length) count++;
  if (commercialConditionTypes.value.length) count++;

  return count;
});
</script>

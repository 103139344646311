<template>
  <PageContainer>
    <PageHeader title="Conditions commerciales" :icon="mapIcon.commercial_condition">
      <HeaderActions>
        <Button @click="showConditionCommercialCreateDialog = true">
          <ButtonIcon :icon="mapIcon.create" />
          Créer une condition commerciale
        </Button>
      </HeaderActions>
    </PageHeader>

    <CommercialConditionFilterBar
      v-model:search="search"
      v-model:business-units="businessUnits"
      v-model:commercial-condition-types="commercialConditionTypes"
      v-model:commercial-condition-statuses="commercialConditionStatuses"
    />
    <PageBody style="margin-top: 16px">
      <PageBodyWrapper>
        <PageBodyBox>
          <CommercialConditionRow is-header />
          <template v-if="loading">
            <CommercialConditionRow v-for="i in 10" :key="i" is-skeleton />
          </template>

          <VirtualTable v-else :items="filteredCommercialConditions" :item-height="58" :get-key="(item) => item.id">
            <template #item="{ data: commercialCondition }">
              <CommercialConditionRow
                :commercial-condition="commercialCondition"
                @click="commercialConditionOpened = commercialCondition"
                @update:commercial-condition="commercialConditions = updateCollection(commercialConditions, $event)"
              />
            </template>
          </VirtualTable>
        </PageBodyBox>
      </PageBodyWrapper>
    </PageBody>
    <LazyCommercialConditionUpsertDialog
      v-if="showConditionCommercialCreateDialog"
      @on-close="showConditionCommercialCreateDialog = false"
      @on-create="
        commercialConditionOpened = $event;
        commercialConditions = [$event, ...commercialConditions];
      "
    />

    <LazyCommercialConditionSheet
      v-if="commercialConditionOpened"
      :commercial-condition="commercialConditionOpened"
      :is-readable="isReadable"
      @on-close="commercialConditionOpened = undefined"
      @on-delete="commercialConditions = remove(commercialConditions, $event)"
      @on-update="commercialConditions = updateCollection(commercialConditions, $event)"
    />
  </PageContainer>
</template>

<script setup lang="ts">
import type { CommercialCondition } from "~/utils/supabase.types";
import { commercialConditionColumns } from "~/utils/supabase.columns";
import type { CommercialConditionStatus, CommercialConditionType } from "@asap/shared";
import { getFullName, translateCommercialConditionType } from "@asap/shared";

const { teamMember, workspaceId, isIndependent } = useUserMe();

const showConditionCommercialCreateDialog = ref(false);
const commercialConditionOpened = ref<CommercialCondition>();
const commercialConditions = ref<CommercialCondition[]>([]);

const isReadable = computed<boolean>(() => {
  const commercialConditionOpenedOwnerWorkspaceId =
    commercialConditionOpened.value?.auth_user.team_member?.workspace_id;

  // Allow access to commercial condition to internal users or to independent if they are the owner
  return (
    !isIndependent.value ||
    (workspaceId.value === commercialConditionOpenedOwnerWorkspaceId &&
      commercialConditionOpened.value?.status !== "to_validate")
  );
});

const search = ref("");
const businessUnits = ref<BusinessUnit[]>([]);
const commercialConditionStatuses = ref<CommercialConditionStatus[]>([]);
const commercialConditionTypes = ref<CommercialConditionType[]>([]);

const supabase = useSupabase();

const loading = ref(false);

const { results } = useFuse(search, commercialConditions, {
  fuseOptions: {
    keys: [
      "company.name",
      "business_unit.name",
      {
        name: "created_by",
        getFn: (commercialCondition) =>
          commercialCondition.created_by ? getFullName(commercialCondition.created_by) : "",
      },
      {
        name: "type",
        getFn: (commercialCondition) => translateCommercialConditionType.fr[commercialCondition.type],
      },
    ],
    threshold: 0.2,
  },
  matchAllWhenSearchEmpty: true,
});

const query = async () => {
  loading.value = true;
  const query = supabase
    .from("commercial_condition")
    .select(commercialConditionColumns)
    .order("created_at", { ascending: false });
  if (businessUnits.value.length)
    query.in(
      "business_unit_id",
      businessUnits.value.map((businessUnit) => businessUnit.id)
    );

  if (commercialConditionTypes.value.length) query.in("type", commercialConditionTypes.value);
  if (commercialConditionStatuses.value.length) query.in("status", commercialConditionStatuses.value);

  const { data, error } = await query;
  if (error) throw error;

  commercialConditions.value = data;

  loading.value = false;
};

watchEffect(() => {
  if (teamMember.value?.business_unit) businessUnits.value = [teamMember.value.business_unit];
});

const filteredCommercialConditions = computed(() => results.value.map((result) => result.item));

watchEffect(query);
</script>
